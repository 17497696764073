import React, { useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  CardHeader,
  useMediaQuery,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom"; // Make sure to import Link if you're using react-router-dom

import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import InsightsIcon from "@mui/icons-material/Insights";
import StarIcon from "@mui/icons-material/Star";
import { highlights } from "../../../data/highlights/highlights";
import { useShare } from "../../../context/ShareContext";

const MyCard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <a href={props.link} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          height: "95%",
          m: 1,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.3)",
        }}
      >
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <CardMedia
            component="img"
            sx={{ maxHeight: "140px", maxWidth: "500px" }}
            image={props.img}
          />
          <CardContent
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ color: colors.black[900] }}
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
};

const Homepage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setShareData } = useShare();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const newShareData = {
      url: `https://chessboardmagic.com`,
      title: "Chessboard Magic",
      description:
        "Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const getRandomHighlight = () => {
    const now = new Date();

    // Get the start of the year
    const startOfYear = new Date(now.getFullYear(), 0, 0);

    // Calculate the difference in milliseconds between now and the start of the year
    const diff = now - startOfYear;

    // Convert the difference to days (milliseconds to days)
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    // Use the day of the year to pick an item from the highlights list
    const index = dayOfYear % highlights.length;

    return highlights[index];
  };

  const highlight = getRandomHighlight();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Helmet>
        <title>Chessboard Magic</title>
        <meta
          name="description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta property="og:title" content="Chessboard Magic" />
        <meta
          property="og:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chessboard Magic" />
        <meta
          name="twitter:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title="Welcome to Chessboard Magic"
        subtitle="A magical place for Chess Puzzles, Tools and more..."
        color={colors.black[900]}
        backgroundImage="./img/header-background.png"
      />
      <Box display="flex" flexDirection="column">
        <Card
          sx={{
            width: "100%",
            margin: "auto",
            mb: 3,
            background: "#283593", //colors.red[500],
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.7)",
          }}
        >
          <CardHeader
            avatar={
              <StarIcon
                sx={{ color: "#EEEEEE", marginRight: 0 }} // Remove right margin or padding
              />
            }
            title={
              <Link to={highlight.url} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px", // Adjust the gap between the elements as needed
                  }}
                >
                  <Typography
                    variant="h6" // Adjust the variant as needed
                    sx={{ color: "#EEEEEE" }} // Replace with your font family
                  >
                    <b>{highlight.title}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#EEEEEE", pt: 0.5, pl: 1 }}
                  >
                    Try it now
                  </Typography>
                </Box>
              </Link>
            }
            sx={{
              borderBottom: "0.5px solid #000000",
              p: 1,
              "& .MuiCardHeader-avatar": {
                marginRight: 1, // Remove right margin of the avatar container
              },
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              gap: "16px",
            }}
          >
            <div style={{ flex: "1" }} sx={{ p: 2, m: 2 }}>
              <Link to={highlight.url}>
                <CardMedia
                  component="img"
                  image={`${process.env.PUBLIC_URL}/img/${highlight.image}.png`}
                  alt={highlight.title}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Link>
            </div>
            <div
              style={{
                flex: "1",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <CardContent>
                <Typography color="text.secondary" sx={{ color: "#EEEEEE" }}>
                  {highlight.description}
                </Typography>
              </CardContent>
            </div>
          </div>
        </Card>

        <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              paddingBottom: "10px",
              color: colors.black[900],
            }}
          >
            <ExtensionRoundedIcon style={{ color: colors.black[900] }} />
            Games
          </Typography>
          <Box sx={{ paddingBottom: "10px" }}>
            <Carousel
              responsive={responsive}
              arrows
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              draggable={true}
              itemClass="carousel-item-padding-40-px"
            >
              <MyCard
                title="Play the Opening"
                description="Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings"
                img="./img/games/playtheopening.png"
                link="playtheopening"
              />
              <MyCard
                title="Guess the Opening"
                description="A Wordle-like chess game where you challenge yourself to figure out the opening move sequence"
                img="./img/games/guesstheopening.png"
                link="guesstheopening"
              />
              <MyCard
                title="Guess the Elo"
                description="Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo"
                img="./img/games/guesstheelo.png"
                link="guesstheelo"
              />
              <MyCard
                title="Guess The Eval"
                description="Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess"
                img="./img/games/guesstheeval.png"
                link="guesstheeval"
              />
              <MyCard
                title="Chess Slide"
                description="Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills"
                img="./img/games/chessslide.png"
                link="chessslide"
              />
              <MyCard
                title="Chess Anagrams"
                description="Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!"
                img="./img/games/anagrams.png"
                link="anagrams"
              />
              <MyCard
                title="Chess Hangman"
                description="Combine the fun of hangman with chess! Guess famous players or chess terms. Challenge your chess knowledge"
                img="./img/games/hangman.png"
                link="hangman"
              />
              <MyCard
                title="Chess Wordsearch"
                description="Challenge your mind with Chess Wordsearch! Find hidden chess terms in a grid and enhance your chess vocabulary"
                img="./img/games/wordsearch.png"
                link="wordsearch"
              />
              <MyCard
                title="Chess Crossword"
                description="Solve chess-themed crosswords and improve your chess knowledge while having fun!"
                img="./img/games/crossword.png"
                link="crossword"
              />
              <MyCard
                title="Chess Image Puzzle"
                description="Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience"
                img="./img/games/imagePuzzle.png"
                link="imagepuzzle"
              />
            </Carousel>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              paddingBottom: "10px",
              color: colors.black[900],
            }}
          >
            <InsightsIcon style={{ color: colors.black[900] }} />
            Analytics
          </Typography>
          <Box>
            <Carousel
              responsive={responsive}
              arrows
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              draggable={true}
              itemClass="carousel-item-padding-40-px"
            >
              <MyCard
                title="User Scout"
                description="Discover and analyze player profiles on Lichess with User Scout! Track progress, review stats, and gain insights effortlessly."
                img="./img/analytics/userscout.png"
                link="userscout"
              />
              <MyCard
                title="Opponent Prep"
                description="Compare two Lichess profiles, analyze game stats, and explore opening strategies side by side. Perfect for chess prep!"
                img="./img/analytics/opponentprep.png"
                link="opponentprep"
              />
            </Carousel>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              paddingBottom: "10px",
              color: colors.black[900],
            }}
          >
            <HandymanIcon style={{ color: colors.black[900] }} />
            Tools
          </Typography>
          <Box>
            <Carousel
              responsive={responsive}
              arrows
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              draggable={true}
              itemClass="carousel-item-padding-40-px"
            >
              <MyCard
                title="Chess Notation Trainer"
                description="Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!"
                img="./img/tools/notationtrainer.png"
                link="notationtrainer"
              />
              <MyCard
                title="Gif Generator"
                description="Turn Chess PGNs into visually engaging sequences effortlessly with our GIF Generator."
                img="./img/tools/gifgenerator.png"
                link="gifgenerator"
              />
              <MyCard
                title="Fischer Random Generator"
                description="Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess"
                img="./img/tools/fischerrandom.png"
                link="fischerrandom"
              />
              <MyCard
                title="Opening Explorer"
                description="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves"
                img="./img/tools/openingexplorer.png"
                link="openingexplorer"
              />
            </Carousel>
          </Box>
        </Box>
        <Box sx={{ paddingBottom: "20px" }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              paddingBottom: "20px",
              color: colors.black[900],
            }}
          >
            <LocalLibraryRoundedIcon style={{ color: colors.black[900] }} />
            Library
          </Typography>
          <Box>
            <Carousel
              responsive={responsive}
              arrows
              infinite
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
              draggable={true}
              itemClass="carousel-item-padding-40-px"
            >
              <MyCard
                title="World Chess Championships"
                description="Explore and play through the World Chess Championship games, featuring matches from the world's top players"
                img="./img/library/worldchampionships.png"
                link="worldchampionships"
              />
              <MyCard
                title="Classic Chess Games"
                description="Explore and play through Classic chess games, featuring matches from some of the greatest players in history"
                img="./img/library/classicgames.png"
                link="classicgames"
              />
              <MyCard
                title="Miniature Chess Games"
                description="Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players."
                img="./img/library/miniaturegames.png"
                link="miniaturegames"
              />
              <MyCard
                title="Chess Quotes"
                description="Discover Famous Chess Quotes: A Collection of Inspiring Words"
                img="./img/library/quotes.png"
                link="quotes"
              />
              <MyCard
                title="Chess Glossary"
                description="Explore and demystify Chess terminology"
                img="./img/library/glossary.png"
                link="glossary"
              />
              <MyCard
                title="Basic Rules of Play"
                description="Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board."
                img="./img/library/basicrulesofplay.png"
                link="basicrulesofplay"
              />
              <MyCard
                title="Competitive Rules of Play"
                description="Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution."
                img="./img/library/competitiverulesofplay.png"
                link="competitiverulesofplay"
              />
            </Carousel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// Custom arrow components
const CustomLeftArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        left: 0,
        zIndex: 1,
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#EEEEEE",
        pt: 1,
      }}
    >
      <ChevronLeftRoundedIcon />
    </Box>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 0,
        zIndex: 1,
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#EEEEEE",
        pt: 1,
      }}
    >
      <ChevronRightRoundedIcon />
    </Box>
  );
};

export default Homepage;
