import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  useTheme,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { miniatureGames } from "../../../data/historicgames/miniatureGames";
import GameViewer from "../../../components/GameViewer";
import ShuffleRoundedIcon from "@mui/icons-material/ShuffleRounded";
import { useShare } from "../../../context/ShareContext";

function MiniatureGames() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/miniaturegames",
      title: "Chessboard Magic - Chess Miniature",
      description:
        "Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players. Perfect for quick learning and inspiration!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const [selectedEventIndex, setSelectedEventIndex] = useState(0);

  useEffect(() => {
    // Select a random game from the miniatureGames array
    const randomIndex = Math.floor(Math.random() * miniatureGames.length);
    const randomGameName = miniatureGames[randomIndex].Name;

    // Find the index of the selected random game
    const initialEventIndex = miniatureGames.findIndex(
      (event) => event.Name === randomGameName
    );

    // Set the selected event index to the random game
    if (initialEventIndex !== -1) {
      setSelectedEventIndex(initialEventIndex);
    }
  }, []);

  const handleEventChange = (event) => {
    setSelectedEventIndex(event.target.value);
  };

  const handleRandomGame = () => {
    const randomIndex = Math.floor(Math.random() * miniatureGames.length);
    setSelectedEventIndex(randomIndex);
  };

  return (
    <Box>
      <ContentHeader
        title="Miniature Chess Games"
        subtitle="Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players. Perfect for quick learning and inspiration!"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Helmet>
        <title>Miniature Chess Games</title>
        <meta
          name="description"
          content="Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players. Perfect for quick learning and inspiration!"
        />
        <meta property="og:title" content="Miniature Chess Games" />
        <meta
          property="og:description"
          content="Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players. Perfect for quick learning and inspiration!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/library/miniaturegames.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/miniaturegames`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Miniature Chess Games" />
        <meta
          name="twitter:description"
          content="Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players. Perfect for quick learning and inspiration!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/library/miniaturegames.png`}
        />
      </Helmet>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Button
            variant="contained"
            onClick={handleRandomGame}
            sx={{
              marginRight: 2,
              height: "35px",
              mt: 0.7,
              width: "35px", // Set a fixed width
              minWidth: "35px", // Ensure the width is applied
            }}
          >
            <ShuffleRoundedIcon />
          </Button>
          <FormControl variant="outlined" margin="normal">
            <Select
              id="event-select"
              value={selectedEventIndex}
              onChange={handleEventChange}
              sx={{ maxWidth: "500px" }}
            >
              {miniatureGames.map((event, index) => (
                <MenuItem key={index} value={index}>
                  <Typography component="span">{event.Name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {miniatureGames[selectedEventIndex] && (
          <Box>
            <GameViewer
              BlackPlayer={miniatureGames[selectedEventIndex].Black}
              BlackElo={miniatureGames[selectedEventIndex].BlackElo}
              WhitePlayer={miniatureGames[selectedEventIndex].White}
              WhiteElo={miniatureGames[selectedEventIndex].WhiteElo}
              Round={miniatureGames[selectedEventIndex].Round}
              PlyCount={miniatureGames[selectedEventIndex].PlyCount}
              Moves={miniatureGames[selectedEventIndex].Moves}
              WhiteImage={miniatureGames[selectedEventIndex].White.split(
                ", "
              )[0].toUpperCase()}
              BlackImage={miniatureGames[selectedEventIndex].Black.split(
                ", "
              )[0].toUpperCase()}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default MiniatureGames;
