import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Alert,
  useTheme,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import {
  eco_1,
  eco_2,
  eco_3,
  eco_4,
  eco_5,
  eco_6,
  eco_7,
  eco_8,
} from "../../../data/eco/eco";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import BulletIcon from "../../../icons/BulletIcon";
import BoltIcon from "../../../icons/BoltIcon";
import RapidIcon from "../../../icons/RapidIcon";
import DailyIcon from "../../../icons/DailyIcon";
import ClassicalIcon from "../../../icons/ClassicalIcon";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { useUser } from "../../../context/UserContext";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { useShare } from "../../../context/ShareContext";

function OpponentPrep() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setUsername] = useState("");
  const [opponent, setOpponent] = useState("");
  const [loading, setLoading] = useState(false);
  const [userRatingHistory, setUserRatingHistory] = useState(null);
  const [opponentRatingHistory, setOpponentRatingHistory] = useState(null);
  const [selectedGameType, setSelectedGameType] = useState("Rapid");
  const [timeAggregation, setTimeAggregation] = useState("Month");
  const [activeTab, setActiveTab] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("user_games");
  const [tableData, setTableData] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [colorView, setColorView] = useState("white");
  const [totalGames, setTotalGames] = useState(0);
  const [totalUserGames, setTotalUserGames] = useState(0);
  const [totalOpponentGames, setTotalOpponentGames] = useState(0);
  const [numberOfGames, setNumberOfGames] = useState(500);
  const [userPerformanceData, setUserPerformanceData] = useState([]);
  const [opponentPerformanceData, setOpponentPerformanceData] = useState([]);
  const [filterGames, setFilterGames] = useState(false);
  const [moveIndex, setMoveIndex] = useState(0);
  const [playedMoves, setPlayedMoves] = useState("");
  const { userData } = useUser();
  const { setShareData } = useShare();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const gameRef = useRef(new Chess());
  const [boardPosition, setBoardPosition] = useState(gameRef.current.fen());

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/opponentprep",
      title: "Chessboard Magic - Opponent Prep",
      description:
        "Compare two Lichess profiles, analyze game stats, and explore opening strategies side by side. Perfect for chess prep!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const showAlert = (message, severity = "info") => {
    console.log("Message: ", message);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const handleSelectChange = (event) => {
    handleTabChange(event, event.target.value);
  };

  const gameTypeLabels = {
    ultraBullet: "Ultrabullet",
    bullet: "Bullet",
    blitz: "Blitz",
    rapid: "Rapid",
    classical: "Classical",
    correspondence: "Correspondence",
  };

  const getPerformanceStatistics = async () => {
    const gameTypes = Object.keys(gameTypeLabels);

    // Get User Performance Data
    const baseUrl = `https://lichess.org/api/user/${username}/perf`;
    const promises = gameTypes.map(async (gameType) => {
      try {
        const response = await fetch(`${baseUrl}/${gameType}`);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        return {
          gameType: gameTypeLabels[gameType],
          data: data,
        };
      } catch (error) {
        console.error(
          `Error fetching data for ${gameTypeLabels[gameType]}:`,
          error
        );
        return {
          gameType: gameTypeLabels[gameType],
          data: null,
        };
      }
    });
    const userPerformanceData = await Promise.all(promises);
    setUserPerformanceData(userPerformanceData);

    // Get User Performance Data
    const opponentBaseUrl = `https://lichess.org/api/user/${opponent}/perf`;
    const opponentPromises = gameTypes.map(async (gameType) => {
      try {
        const response = await fetch(`${opponentBaseUrl}/${gameType}`);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        return {
          gameType: gameTypeLabels[gameType],
          data: data,
        };
      } catch (error) {
        console.error(
          `Error fetching data for ${gameTypeLabels[gameType]}:`,
          error
        );
        return {
          gameType: gameTypeLabels[gameType],
          data: null,
        };
      }
    });
    const opponentPerformanceData = await Promise.all(opponentPromises);
    setOpponentPerformanceData(opponentPerformanceData);
  };

  // Set default dates
  const today = new Date().toISOString().split("T")[0];
  const startingDate = new Date();
  startingDate.setFullYear(startingDate.getFullYear() - 3);
  const defaultStartDate = startingDate.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(today);

  const mergedEcos = [
    ...eco_1,
    ...eco_2,
    ...eco_3,
    ...eco_4,
    ...eco_5,
    ...eco_6,
    ...eco_7,
    ...eco_8,
  ];

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleOpponentChange = (e) => {
    setOpponent(e.target.value);
  };

  const handleNumberOfGamesChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setNumberOfGames(parsedValue);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFetchAll = async () => {
    setLoading(true);
    try {
      await getProfile();
      await handleDownloadAndProcessGames();
    } catch (error) {
      console.error("Failed to fetch and process data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadAndProcessGames = async () => {
    if (!username || !opponent) {
      return;
    }
    const newGamesWithOpenings = [];
    setTotalGames(0);
    setGameData([]);

    const processGamesForUser = async (user) => {
      const newGameTypeStats = {
        ultrabullet: { games: 0, wins: 0, draws: 0, losses: 0 },
        bullet: { games: 0, wins: 0, draws: 0, losses: 0 },
        blitz: { games: 0, wins: 0, draws: 0, losses: 0 },
        rapid: { games: 0, wins: 0, draws: 0, losses: 0 },
        classical: { games: 0, wins: 0, draws: 0, losses: 0 },
        correspondence: { games: 0, wins: 0, draws: 0, losses: 0 },
      };

      let userTotalGames = 0;

      try {
        const response = await fetch(
          `https://lichess.org/api/games/user/${user}?max=${numberOfGames}&format=ndjson&lastFen=true&division=true`,
          {
            headers: {
              Accept: "application/x-ndjson",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let ndjsonData = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          ndjsonData += decoder.decode(value);
          const lines = ndjsonData.split("\n");
          ndjsonData = lines.pop(); // Keep the last incomplete line for the next read

          for (const l of lines) {
            let game;
            try {
              game = JSON.parse(l);
            } catch (e) {
              console.error("Failed to parse line as JSON:", l);
              continue;
            }

            if (!game.moves || typeof game.moves !== "string") {
              continue;
            }

            // Check if player names exist
            if (
              !game.players?.white?.user?.name ||
              !game.players?.black?.user?.name
            ) {
              console.warn("Game skipped due to missing player name:", game);
              continue;
            }

            const moves = game.moves.split(" ");
            const isWhite =
              game.players.white.user.name.toLowerCase() === user.toLowerCase();
            const winner = game.winner;
            const gameType = game.speed.toLowerCase(); // Get the game type (e.g., bullet, blitz)

            // Update game type stats
            newGameTypeStats[gameType].games += 1;
            if (winner === "white" && isWhite) {
              newGameTypeStats[gameType].wins += 1;
            } else if (winner === "black" && !isWhite) {
              newGameTypeStats[gameType].wins += 1;
            } else if (winner === "draw") {
              newGameTypeStats[gameType].draws += 1;
            } else {
              newGameTypeStats[gameType].losses += 1;
            }

            let openingName = "Unknown Opening";
            let ecoCode = "";
            let line = "";
            for (let i = 1; i <= 4; i++) {
              const currentMoves = moves.slice(0, i).join(" ");
              const matchedOpening = mergedEcos.find((eco) => {
                const ecoMoves = eco.pgn.replace(/[0-9]+\.\s/g, "").trim();
                return ecoMoves.startsWith(currentMoves);
              });
              if (matchedOpening) {
                openingName = matchedOpening.name;
                ecoCode = matchedOpening.eco;
                line = matchedOpening.pgn;
              } else {
                break;
              }
            }

            const gameDataEntry = {
              id: game.id,
              opening: openingName,
              eco: ecoCode,
              line: line,
              pgn: game.moves,
              speed: game.speed,
              whitePlayer: game.players.white.user.name,
              whiteRating: game.players.white.rating,
              blackPlayer: game.players.black.user.name,
              blackRating: game.players.black.rating,
              winner: game.winner,
              rated: game.rated,
              moves: moves.length,
              middle: game.division.middle,
              end: game.division.end,
              user: user,
            };

            // Push to both newGamesWithOpenings and gameData state
            newGamesWithOpenings.push(gameDataEntry);
            setGameData((prevGameData) => [...prevGameData, gameDataEntry]);

            userTotalGames += 1;

            if (user === username) {
              setTotalUserGames(userTotalGames);
            } else if (user === opponent) {
              setTotalOpponentGames(userTotalGames);
            }

            setTotalGames((prev) => prev + 1);

            // Update table data after processing each game
            updateTableData(newGamesWithOpenings);
          }
        }

        // Update the total games state for the specific user
      } catch (error) {
        showAlert(`Failed to fetch and process games for ${user}`, "error");
        return [];
      }
    };

    setLoading(true);

    try {
      setFilterGames(false);
      await processGamesForUser(username);
      setFilterGames(true);
      await processGamesForUser(opponent);
    } finally {
      setLoading(false);
    }
  };

  const updateTableData = (gamesWithOpenings) => {
    const upperUsername = username.toUpperCase();
    const upperOpponent = opponent.toUpperCase();

    const groupedGames = gamesWithOpenings.reduce((acc, game) => {
      if (game.user === username) {
        const whitePlayer = game.whitePlayer.toUpperCase();
        const blackPlayer = game.blackPlayer.toUpperCase();
        const color = whitePlayer === upperUsername ? "white" : "black";
        const userRating =
          color === "white" ? game.whiteRating : game.blackRating;
        const opponentRating =
          color === "white" ? game.blackRating : game.whiteRating;

        const result =
          game.winner === color
            ? "win"
            : game.winner === undefined || game.winner === "draw"
            ? "draw"
            : "loss";

        if (!acc[game.opening]) {
          acc[game.opening] = {
            eco: game.eco,
            line: game.line,
            color: color,
            color_filter: color,
            user_games: 0,
            user_wins: 0,
            user_losses: 0,
            user_draws: 0,
            user_totalMoves: 0,
            user_totalMiddle: 0,
            user_totalEnd: 0,
            user_scoreVsStronger: 0,
            user_scoreVsEqual: 0,
            user_scoreVsWeaker: 0,
            user_WinsVsStronger: 0,
            user_DrawsVsStronger: 0,
            user_LossesVsStronger: 0,
            user_WinsVsEqual: 0,
            user_DrawsVsEqual: 0,
            user_LossesVsEqual: 0,
            user_WinsVsWeaker: 0,
            user_DrawsVsWeaker: 0,
            user_LossesVsWeaker: 0,
            opponent_games: 0,
            opponent_wins: 0,
            opponent_losses: 0,
            opponent_draws: 0,
            opponent_totalMoves: 0,
            opponent_totalMiddle: 0,
            opponent_totalEnd: 0,
            opponent_scoreVsStronger: 0,
            opponent_scoreVsEqual: 0,
            opponent_scoreVsWeaker: 0,
            opponent_WinsVsStronger: 0,
            opponent_DrawsVsStronger: 0,
            opponent_LossesVsStronger: 0,
            opponent_WinsVsEqual: 0,
            opponent_DrawsVsEqual: 0,
            opponent_LossesVsEqual: 0,
            opponent_WinsVsWeaker: 0,
            opponent_DrawsVsWeaker: 0,
            opponent_LossesVsWeaker: 0,
          };
        } else {
          acc[game.opening].color = color;
        }

        acc[game.opening].user_games += 1;
        acc[game.opening].user_totalMoves += game.moves ?? 0;
        acc[game.opening].user_totalMiddle += game.middle ?? 0;
        acc[game.opening].user_totalEnd += game.end ?? 0;

        if (game.winner === "white" && whitePlayer === upperUsername) {
          acc[game.opening].user_wins += 1;
        } else if (game.winner === "black" && blackPlayer === upperUsername) {
          acc[game.opening].user_wins += 1;
        } else if (!game.winner) {
          acc[game.opening].user_draws += 1;
        } else {
          acc[game.opening].user_losses += 1;
        }

        if (opponentRating >= userRating + 50) {
          // Opponent is stronger
          acc[game.opening].user_scoreVsStronger +=
            result === "win" ? 1 : result === "loss" ? -1 : 0.5;
          if (result === "win") {
            acc[game.opening].user_WinsVsStronger += 1;
          } else if (result === "loss") {
            acc[game.opening].user_LossesVsStronger += 1;
          } else {
            acc[game.opening].user_DrawsVsStronger += 1;
          }
        } else if (opponentRating <= userRating - 50) {
          // Opponent is weaker
          acc[game.opening].user_scoreVsWeaker +=
            result === "win" ? 1 : result === "loss" ? -1 : 0.5;
          if (result === "win") {
            acc[game.opening].user_WinsVsWeaker += 1;
          } else if (result === "loss") {
            acc[game.opening].user_LossesVsWeaker += 1;
          } else {
            acc[game.opening].user_DrawsVsWeaker += 1;
          }
        } else {
          // Opponent is equal
          acc[game.opening].user_scoreVsEqual +=
            result === "win" ? 1 : result === "loss" ? -1 : 0.5;
          if (result === "win") {
            acc[game.opening].user_WinsVsEqual += 1;
          } else if (result === "loss") {
            acc[game.opening].user_LossesVsEqual += 1;
          } else {
            acc[game.opening].user_DrawsVsEqual += 1;
          }
        }
      } else {
        const whitePlayer = game.whitePlayer.toUpperCase();
        const blackPlayer = game.blackPlayer.toUpperCase();
        const color = whitePlayer === upperOpponent ? "white" : "black";
        const userRating =
          color === "white" ? game.whiteRating : game.blackRating;
        const opponentRating =
          color === "white" ? game.blackRating : game.whiteRating;

        const result =
          game.winner === color
            ? "win"
            : game.winner === undefined || game.winner === "draw"
            ? "draw"
            : "loss";

        if (!acc[game.opening]) {
          acc[game.opening] = {
            eco: game.eco,
            line: game.line,
            color: color,
            color_filter: whitePlayer === upperOpponent ? "black" : "white",
            user_games: 0,
            user_wins: 0,
            user_losses: 0,
            user_draws: 0,
            user_totalMoves: 0,
            user_totalMiddle: 0,
            user_totalEnd: 0,
            user_scoreVsStronger: 0,
            user_scoreVsEqual: 0,
            user_scoreVsWeaker: 0,
            user_WinsVsStronger: 0,
            user_DrawsVsStronger: 0,
            user_LossesVsStronger: 0,
            user_WinsVsEqual: 0,
            user_DrawsVsEqual: 0,
            user_LossesVsEqual: 0,
            user_WinsVsWeaker: 0,
            user_DrawsVsWeaker: 0,
            user_LossesVsWeaker: 0,
            opponent_games: 0,
            opponent_wins: 0,
            opponent_losses: 0,
            opponent_draws: 0,
            opponent_totalMoves: 0,
            opponent_totalMiddle: 0,
            opponent_totalEnd: 0,
            opponent_scoreVsStronger: 0,
            opponent_scoreVsEqual: 0,
            opponent_scoreVsWeaker: 0,
            opponent_WinsVsStronger: 0,
            opponent_DrawsVsStronger: 0,
            opponent_LossesVsStronger: 0,
            opponent_WinsVsEqual: 0,
            opponent_DrawsVsEqual: 0,
            opponent_LossesVsEqual: 0,
            opponent_WinsVsWeaker: 0,
            opponent_DrawsVsWeaker: 0,
            opponent_LossesVsWeaker: 0,
          };
        } else {
          acc[game.opening].color =
            whitePlayer === upperOpponent ? "black" : "white";
        }

        acc[game.opening].opponent_games += 1;
        acc[game.opening].opponent_totalMoves += game.moves ?? 0;
        acc[game.opening].opponent_totalMiddle += game.middle ?? 0;
        acc[game.opening].opponent_totalEnd += game.end ?? 0;

        if (game.winner === "white" && whitePlayer === upperOpponent) {
          acc[game.opening].opponent_wins += 1;
        } else if (game.winner === "black" && blackPlayer === upperOpponent) {
          acc[game.opening].opponent_wins += 1;
        } else if (!game.winner) {
          acc[game.opening].opponent_draws += 1;
        } else {
          acc[game.opening].opponent_losses += 1;
        }

        if (opponentRating >= userRating + 50) {
          // Opponent is stronger
          acc[game.opening].opponent_scoreVsStronger +=
            result === "win" ? 1 : result === "loss" ? -1 : 0.5;
          if (result === "win") {
            acc[game.opening].opponent_WinsVsStronger += 1;
          } else if (result === "loss") {
            acc[game.opening].opponent_LossesVsStronger += 1;
          } else {
            acc[game.opening].opponent_DrawsVsStronger += 1;
          }
        } else if (opponentRating <= userRating - 50) {
          // Opponent is weaker
          acc[game.opening].opponent_scoreVsWeaker +=
            result === "win" ? 1 : result === "loss" ? -1 : 0.5;
          if (result === "win") {
            acc[game.opening].opponent_WinsVsWeaker += 1;
          } else if (result === "loss") {
            acc[game.opening].opponent_LossesVsWeaker += 1;
          } else {
            acc[game.opening].opponent_DrawsVsWeaker += 1;
          }
        } else {
          // Opponent is equal
          acc[game.opening].opponent_scoreVsEqual +=
            result === "win" ? 1 : result === "loss" ? -1 : 0.5;
          if (result === "win") {
            acc[game.opening].opponent_WinsVsEqual += 1;
          } else if (result === "loss") {
            acc[game.opening].opponent_LossesVsEqual += 1;
          } else {
            acc[game.opening].opponent_DrawsVsEqual += 1;
          }
        }
      }

      return acc;
    }, {});

    const tableData = Object.keys(groupedGames).map((opening) => ({
      opening,
      eco: groupedGames[opening].eco,
      line: groupedGames[opening].line,
      color: groupedGames[opening].color,
      ...groupedGames[opening],
      averageMoves: (
        groupedGames[opening].totalMoves / groupedGames[opening].games
      ).toFixed(1),
      averageMiddle: isNaN(
        groupedGames[opening].totalMiddle / groupedGames[opening].games
      )
        ? "N/A"
        : (
            groupedGames[opening].totalMiddle / groupedGames[opening].games
          ).toFixed(1),
      averageEnd: isNaN(
        groupedGames[opening].totalEnd / groupedGames[opening].games
      )
        ? "N/A"
        : (
            groupedGames[opening].totalEnd / groupedGames[opening].games
          ).toFixed(1),
    }));

    setTableData(tableData);
  };

  const getProfile = async () => {
    setAlertMessage("");

    if (!username) {
      showAlert("Please enter a username", "warning");
      return;
    }
    if (!opponent) {
      showAlert("Please enter an opponent", "warning");
      return;
    }

    setLoading(true);

    try {
      await handleFetchPublicData();
      await handleFetchRatingHistory();
      await getPerformanceStatistics();
    } catch (error) {
      console.error("Failed to fetch profile data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchRatingHistory = async () => {
    if (!username) {
      return;
    }
    if (!opponent) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://lichess.org/api/user/${username}/rating-history`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const ratingHistoryData = await response.json();
      setUserRatingHistory(ratingHistoryData);
    } catch (error) {
      console.error("Failed to fetch user rating history", error);
    }

    try {
      const response = await fetch(
        `https://lichess.org/api/user/${opponent}/rating-history`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const opponentRatingHistoryData = await response.json();
      setOpponentRatingHistory(opponentRatingHistoryData);
    } catch (error) {
      console.error("Failed to fetch opponent rating history", error);
    }

    setLoading(false);
  };

  const handleFetchPublicData = async () => {
    if (!username || !opponent) {
      return;
    }

    setLoading(true);
  };

  const handleGameTypeChange = (event) => {
    setSelectedGameType(event.target.value);
  };

  const handleTimeAggregationChange = (event) => {
    setTimeAggregation(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleColorViewChange = (event, newView) => {
    if (newView !== null) {
      setColorView(newView);
    }
  };

  const last60Days = new Date();
  last60Days.setDate(last60Days.getDate() - 2000);

  const fillGaps = (data) => {
    const filledData = [];
    let lastRating = null;

    const dataMap = new Map(data.map((d) => [d.x, d.y]));

    const startDate = new Date(Math.min(...data.map((d) => new Date(d.x))));
    const endDate = new Date();

    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const dateString = d.toISOString().split("T")[0];

      if (dataMap.has(dateString)) {
        lastRating = dataMap.get(dateString);
      }

      if (lastRating !== null) {
        filledData.push({ x: dateString, y: lastRating });
      }
    }

    return filledData;
  };

  const filterDataByAggregation = (data, aggregation) => {
    switch (aggregation) {
      case "Week":
        return data.filter((d) => new Date(d.x).getDay() === 1);
      case "Month":
        return data.filter((d) => new Date(d.x).getDate() === 1);
      default:
        return data;
    }
  };

  const filterDataByDateRange = (data) => {
    if (!startDate && !endDate) {
      return data;
    }

    return data.filter((d) => {
      const date = new Date(d.x);
      return (
        (!startDate || date >= new Date(startDate)) &&
        (!endDate || date <= new Date(endDate))
      );
    });
  };

  const filteredGameData = gameData.filter((game) => {
    if (colorView === "white") {
      // Filter games where user is White or opponent is Black
      return (
        game.whitePlayer.toLowerCase() === username.toLowerCase() ||
        game.blackPlayer.toLowerCase() === opponent.toLowerCase()
      );
    } else if (colorView === "black") {
      // Filter games where opponent is White or user is Black
      return (
        game.whitePlayer.toLowerCase() === opponent.toLowerCase() ||
        game.blackPlayer.toLowerCase() === username.toLowerCase()
      );
    }
    return true;
  });

  const aggregatedGameData = filteredGameData.reduce((acc, game) => {
    const gameMoves = game.pgn.trim().split(" ");
    const playedMovesArray = playedMoves.trim().split(" ");
    const playedMovesLength = playedMovesArray.length;

    // If no moves have been made (moveIndex = 0), consider the first move of each game
    let nextMove =
      moveIndex === 0 ? gameMoves[0] : gameMoves[playedMovesLength];

    // If the played moves don't match up to the current moveIndex, skip this game
    const matchesPlayedMoves =
      gameMoves.slice(0, playedMovesLength).join(" ") === playedMoves;
    if (moveIndex > 0 && !matchesPlayedMoves) {
      return acc;
    }

    // Initialize the nextMove entry in the accumulator if it doesn't exist
    if (!acc[nextMove]) {
      acc[nextMove] = {
        move: nextMove,
        username: {
          gamesPlayed: 0,
          wins: 0,
          losses: 0,
          draws: 0,
        },
        opponent: {
          gamesPlayed: 0,
          wins: 0,
          losses: 0,
          draws: 0,
        },
      };
    }

    // Update the statistics for the user
    if (
      colorView === "white" &&
      game.whitePlayer.toLowerCase() === username.toLowerCase()
    ) {
      acc[nextMove].username.gamesPlayed += 1;
      if (game.winner === "white") {
        acc[nextMove].username.wins += 1;
      } else if (!game.winner) {
        acc[nextMove].username.draws += 1;
      } else {
        acc[nextMove].username.losses += 1;
      }
    } else if (
      colorView === "black" &&
      game.blackPlayer.toLowerCase() === username.toLowerCase()
    ) {
      acc[nextMove].username.gamesPlayed += 1;
      if (game.winner === "black") {
        acc[nextMove].username.wins += 1;
      } else if (!game.winner) {
        acc[nextMove].username.draws += 1;
      } else {
        acc[nextMove].username.losses += 1;
      }
    }

    // Update the statistics for the opponent
    if (
      colorView === "white" &&
      game.blackPlayer.toLowerCase() === opponent.toLowerCase()
    ) {
      acc[nextMove].opponent.gamesPlayed += 1;
      if (game.winner === "black") {
        acc[nextMove].opponent.wins += 1;
      } else if (!game.winner) {
        acc[nextMove].opponent.draws += 1;
      } else {
        acc[nextMove].opponent.losses += 1;
      }
    } else if (
      colorView === "black" &&
      game.whitePlayer.toLowerCase() === opponent.toLowerCase()
    ) {
      acc[nextMove].opponent.gamesPlayed += 1;
      if (game.winner === "white") {
        acc[nextMove].opponent.wins += 1;
      } else if (!game.winner) {
        acc[nextMove].opponent.draws += 1;
      } else {
        acc[nextMove].opponent.losses += 1;
      }
    }

    return acc;
  }, {});

  const filteredUserRatingHistory = userRatingHistory
    ? userRatingHistory
        .filter(
          (history) =>
            history.name.toLowerCase() === selectedGameType.toLowerCase()
        )
        .map((history) => ({
          id: `${username}'s ${history.name} Rating`,
          data: filterDataByDateRange(
            filterDataByAggregation(
              fillGaps(
                history.points
                  .map((entry) => ({
                    x: new Date(entry[0], entry[1] - 1, entry[2])
                      .toISOString()
                      .split("T")[0],
                    y: entry[3],
                  }))
                  .filter((dataPoint) => new Date(dataPoint.x) >= last60Days)
              ),
              timeAggregation
            )
          ),
        }))
        .filter((history) => history.data.length > 0)
    : [];

  const filteredOpponentRatingHistory = opponentRatingHistory
    ? opponentRatingHistory
        .filter(
          (history) =>
            history.name.toLowerCase() === selectedGameType.toLowerCase()
        )
        .map((history) => ({
          id: `${opponent}'s ${history.name} Rating`,
          data: filterDataByDateRange(
            filterDataByAggregation(
              fillGaps(
                history.points
                  .map((entry) => ({
                    x: new Date(entry[0], entry[1] - 1, entry[2])
                      .toISOString()
                      .split("T")[0],
                    y: entry[3],
                  }))
                  .filter((dataPoint) => new Date(dataPoint.x) >= last60Days)
              ),
              timeAggregation
            )
          ),
        }))
        .filter((history) => history.data.length > 0)
    : [];

  // Combine both user's and opponent's data for the chart
  const combinedRatingHistory = [
    ...filteredUserRatingHistory,
    ...filteredOpponentRatingHistory,
  ];

  const gameTypes = userRatingHistory
    ? [
        ...new Set(
          userRatingHistory
            .filter((history) => history.points.length > 0)
            .map((history) => history.name)
        ),
      ]
    : ["All"];

  const sortedTableData = [...tableData].sort((a, b) => {
    if (orderBy === "opening" || orderBy === "eco" || orderBy === "line") {
      return order === "asc"
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === "win_differential") {
      const winDifferentialA =
        (isNaN(a.user_wins / a.user_games)
          ? 0
          : (a.user_wins / a.user_games) * 100) -
        (isNaN(a.opponent_wins / a.opponent_games)
          ? 0
          : (a.opponent_wins / a.opponent_games) * 100);

      const winDifferentialB =
        (isNaN(b.user_wins / b.user_games)
          ? 0
          : (b.user_wins / b.user_games) * 100) -
        (isNaN(b.opponent_wins / b.opponent_games)
          ? 0
          : (b.opponent_wins / b.opponent_games) * 100);

      return order === "asc"
        ? winDifferentialA - winDifferentialB
        : winDifferentialB - winDifferentialA;
    } else {
      return order === "asc"
        ? a[orderBy] - b[orderBy]
        : b[orderBy] - a[orderBy];
    }
  });

  const filteredTableData = sortedTableData.filter((row) => {
    if (filterGames) {
      return (
        row.color_filter === colorView &&
        row.user_games > 0 &&
        row.opponent_games > 0
      );
    } else {
      return row.color_filter === colorView;
    }
  });

  function onDrop(sourceSquare, targetSquare) {
    try {
      // Try making the move using chess.js
      const move = gameRef.current.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q", // Automatically promote to a queen if needed
      });

      // Check if the move is valid
      if (move === null) {
        return false; // Prevent the move from being accepted
      }

      // Update the playedMoves state
      setPlayedMoves((prevMoves) =>
        prevMoves ? `${prevMoves} ${move.san}` : move.san
      );

      setMoveIndex((prev) => prev + 1);

      // Update the board position state with the new FEN string
      setBoardPosition(gameRef.current.fen());

      return true; // Accept the move
    } catch (error) {
      console.error("Invalid move:", error);
      return false; // Prevent the move from being accepted
    }
  }

  const handleResetPlayedMoves = () => {
    setPlayedMoves(""); // Reset playedMoves to an empty string
    gameRef.current.reset(); // Reset the chess.js game state
    setBoardPosition(gameRef.current.fen()); // Update the board position
    setMoveIndex(0);
  };

  const handleBackMove = () => {
    const moveArray = playedMoves.trim().split(" ");

    if (moveArray.length === 0) {
      return; // No moves to go back to
    }

    // Remove the last move from the array
    const newMoveArray = moveArray.slice(0, moveArray.length - 1);
    const newPlayedMoves = newMoveArray.join(" ");

    // Set the updated moves and decrement the move index
    setPlayedMoves(newPlayedMoves);
    setMoveIndex((prev) => Math.max(prev - 1, 0));

    // Reset the game and replay the remaining moves
    gameRef.current.reset();
    newMoveArray.forEach((move) => {
      gameRef.current.move(move);
    });

    // Update the board position with the new FEN
    setBoardPosition(gameRef.current.fen());
  };

  const formatPlayedMoves = (moves) => {
    const moveArray = moves.trim().split(" ");

    // Check if there are no moves
    if (
      moveArray.length === 0 ||
      (moveArray.length === 1 && moveArray[0] === "")
    ) {
      return ""; // Return an empty string if no moves are played
    }

    let formattedMoves = "";

    for (let i = 0; i < moveArray.length; i += 2) {
      const moveNumber = Math.floor(i / 2) + 1;
      const whiteMove = moveArray[i];
      const blackMove = moveArray[i + 1] ? moveArray[i + 1] : "";

      formattedMoves += `${moveNumber}. ${whiteMove} ${blackMove} `;
    }

    return formattedMoves.trim();
  };

  const tabStyles = (isSelected) => ({
    border: "0.5px solid #DDDDDD",
    color: isSelected ? colors.black[900] : colors.black[500],
    "&.Mui-selected": {
      color: colors.black[900],
    },
    "&:hover": {
      color: colors.black[900],
    },
  });

  return (
    <Box>
      <ContentHeader
        title="Opponent Prep"
        subtitle="Compare two Lichess profiles, analyze game stats, and explore opening strategies side by side. Perfect for chess prep!"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Helmet>
        <title>Opponent Prep</title>
        <meta
          name="description"
          content="Compare two Lichess profiles, analyze game stats, and explore opening strategies side by side. Perfect for chess prep!"
        />
        <meta property="og:title" content="Opponent Prep" />
        <meta property="og:description" content="" />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/analytics/opponentprep.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/opponentprep`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Opponent Prep" />
        <meta
          name="twitter:description"
          content="Compare two Lichess profiles, analyze game stats, and explore opening strategies side by side. Perfect for chess prep!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/analytics/opponentprep.png`}
        />
      </Helmet>
      <Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <TextField
              variant="filled"
              label="Lichess Username"
              value={username}
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{ mb: 1, width: "100%" }}
              onChange={handleUsernameChange}
            />
            <TextField
              variant="filled"
              label="Lichess Opponent"
              value={opponent}
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{ mb: 1, width: "100%" }}
              onChange={handleOpponentChange}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <TextField
                variant="filled"
                label="# Games"
                value={numberOfGames}
                type="number"
                InputLabelProps={{
                  style: { color: "black" },
                }}
                inputProps={{ autoComplete: "off" }}
                sx={{ width: "30%", pr: 1 }}
                onChange={handleNumberOfGamesChange}
              />
              <Button
                variant="contained"
                onClick={handleFetchAll}
                disabled={loading}
                sx={{ width: "30%" }}
              >
                {loading ? <CircularProgress size={24} /> : "Import"}
              </Button>
              {alertMessage && (
                <Alert severity={alertSeverity} sx={{ marginLeft: 2 }}>
                  {alertMessage}
                </Alert>
              )}
              <Typography sx={{ ml: 1, width: "30%", textAlign: "center" }}>
                Loaded: <b>{totalGames}</b>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <TextField
              variant="filled"
              label="Lichess Username"
              value={username}
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{ pr: 2 }}
              onChange={handleUsernameChange}
            />
            <TextField
              variant="filled"
              label="Lichess Opponent"
              value={opponent}
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{ pr: 2 }}
              onChange={handleOpponentChange}
            />
            <TextField
              variant="filled"
              label="# Games"
              value={numberOfGames}
              type="number"
              InputLabelProps={{
                style: { color: "black" },
              }}
              inputProps={{ autoComplete: "off" }}
              sx={{ width: 100 }}
              onChange={handleNumberOfGamesChange}
            />
            <Button
              variant="contained"
              onClick={handleFetchAll}
              disabled={loading}
              sx={{ marginLeft: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Import"}
            </Button>
            {alertMessage && (
              <Alert severity={alertSeverity} sx={{ marginLeft: 2 }}>
                {alertMessage}
              </Alert>
            )}
            <Typography sx={{ marginLeft: 2 }}>
              Loaded: <b>{totalGames}</b>
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ marginTop: 2, maxWidth: "1200px" }}>
        <div>
          {isSmallScreen ? (
            <Select
              value={activeTab}
              onChange={handleSelectChange}
              fullWidth
              sx={tabStyles}
            >
              <MenuItem value={0}>Overview</MenuItem>
              <MenuItem value={1}>Ratings Graph</MenuItem>
              <MenuItem value={2}>Opening Table</MenuItem>
              <MenuItem value={3}>Opening Explorer</MenuItem>
            </Select>
          ) : (
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Overview" sx={tabStyles(activeTab === 0)} />
              <Tab label="Ratings Graph" sx={tabStyles(activeTab === 1)} />
              <Tab label="Opening Table" sx={tabStyles(activeTab === 2)} />
              <Tab label="Opening Explorer" sx={tabStyles(activeTab === 3)} />
            </Tabs>
          )}
        </div>
        {
          /* Overview */ activeTab === 0 && (
            <Box mt={2} mb={2}>
              <div>
                {userPerformanceData.length > 0 &&
                  opponentPerformanceData.length > 0 && (
                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", marginTop: 2 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Time Control</TableCell>
                            <TableCell>Current Rating</TableCell>
                            <TableCell>Number of Games</TableCell>
                            <TableCell>Wins</TableCell>
                            <TableCell>Draws</TableCell>
                            <TableCell>Losses</TableCell>
                            <TableCell>W / D / L</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userPerformanceData.map((userItem, index) => {
                            const opponentItem = opponentPerformanceData.find(
                              (item) => item.gameType === userItem.gameType
                            );

                            if (!userItem.data && !opponentItem.data)
                              return null;

                            const renderRatingCell = (
                              userValue,
                              opponentValue
                            ) => {
                              const difference = userValue - opponentValue;
                              const color = difference > 0 ? "green" : "red";

                              return (
                                <>
                                  <b>{userValue}</b>
                                  <br />
                                  vs <b>{opponentValue}</b>{" "}
                                  <small style={{ color }}>
                                    ({difference >= 0 ? "+" : ""}
                                    {difference})
                                  </small>
                                </>
                              );
                            };

                            const renderRow = (item, opponentItem) => {
                              const userRating = Math.floor(
                                item.data.perf.glicko.rating
                              );
                              const userNb = item.data.perf.nb;
                              const userWins = item.data.stat.count.win;
                              const userDraws = item.data.stat.count.draw;
                              const userLosses = item.data.stat.count.loss;

                              let opponentRating = "-";
                              let opponentNb = 0;
                              let opponentWins = 0;
                              let opponentDraws = 0;
                              let opponentLosses = 0;

                              if (opponentItem && opponentItem.data) {
                                opponentRating = Math.floor(
                                  opponentItem.data.perf.glicko.rating
                                );
                                opponentNb = opponentItem.data.perf.nb;

                                // If opponentNb is greater than 0, use the actual stats, otherwise, keep them as zero
                                if (opponentNb > 0) {
                                  opponentWins =
                                    opponentItem.data.stat.count.win;
                                  opponentDraws =
                                    opponentItem.data.stat.count.draw;
                                  opponentLosses =
                                    opponentItem.data.stat.count.loss;
                                }
                              }

                              if (userNb === 0 && opponentNb === 0) return null;

                              const renderBarData = (
                                wins,
                                draws,
                                losses,
                                nb
                              ) => {
                                if (nb === 0) {
                                  return [
                                    {
                                      type: "results",
                                      wins: 0,
                                      draws: 0,
                                      losses: 0,
                                    },
                                  ];
                                }
                                return [
                                  {
                                    type: "results",
                                    wins: (wins / nb) * 100,
                                    draws: (draws / nb) * 100,
                                    losses: (losses / nb) * 100,
                                  },
                                ];
                              };

                              return (
                                <>
                                  <TableCell>
                                    {renderRatingCell(
                                      userRating,
                                      opponentRating
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {renderRatingCell(userNb, opponentNb)}
                                  </TableCell>
                                  <TableCell>
                                    {renderRatingCell(userWins, opponentWins)}
                                  </TableCell>
                                  <TableCell>
                                    {renderRatingCell(userDraws, opponentDraws)}
                                  </TableCell>
                                  <TableCell>
                                    {renderRatingCell(
                                      userLosses,
                                      opponentLosses
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      style={{ height: "20px", width: "150px" }}
                                    >
                                      <ResponsiveBar
                                        data={renderBarData(
                                          userWins,
                                          userDraws,
                                          userLosses,
                                          userNb
                                        )}
                                        keys={["wins", "draws", "losses"]}
                                        indexBy="type"
                                        margin={{
                                          top: 0,
                                          right: 10,
                                          bottom: 0,
                                          left: 0,
                                        }}
                                        layout="horizontal"
                                        colors={({ id }) => {
                                          if (id === "wins")
                                            return colors.green[500]; // green
                                          if (id === "losses")
                                            return colors.red[300]; // red
                                          if (id === "draws")
                                            return colors.grey[500]; // grey
                                          return "#000";
                                        }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={null}
                                        axisLeft={null}
                                        enableLabel={false}
                                        isInteractive={false}
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        theme={{
                                          axis: {
                                            ticks: {
                                              text: {
                                                fontSize: 12,
                                                fill: "#555",
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{ height: "20px", width: "150px" }}
                                    >
                                      <ResponsiveBar
                                        data={renderBarData(
                                          opponentWins,
                                          opponentDraws,
                                          opponentLosses,
                                          opponentNb
                                        )}
                                        keys={["wins", "draws", "losses"]}
                                        indexBy="type"
                                        margin={{
                                          top: 0,
                                          right: 10,
                                          bottom: 0,
                                          left: 0,
                                        }}
                                        layout="horizontal"
                                        colors={({ id }) => {
                                          if (id === "wins")
                                            return colors.green[500]; // green
                                          if (id === "losses")
                                            return colors.red[300]; // red
                                          if (id === "draws")
                                            return colors.grey[500]; // grey
                                          return "#000";
                                        }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={null}
                                        axisLeft={null}
                                        enableLabel={false}
                                        isInteractive={false}
                                        animate={true}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        theme={{
                                          axis: {
                                            ticks: {
                                              text: {
                                                fontSize: 12,
                                                fill: "#555",
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                </>
                              );
                            };

                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {(() => {
                                      let icon;
                                      const iconStyle = {
                                        width: 30,
                                        height: 30,
                                        marginRight: 2,
                                      };
                                      switch (userItem.gameType.toLowerCase()) {
                                        case "ultrabullet":
                                          icon = <BulletIcon sx={iconStyle} />;
                                          break;
                                        case "bullet":
                                          icon = <BulletIcon sx={iconStyle} />;
                                          break;
                                        case "blitz":
                                          icon = <BoltIcon sx={iconStyle} />;
                                          break;
                                        case "rapid":
                                          icon = <RapidIcon sx={iconStyle} />;
                                          break;
                                        case "classical":
                                          icon = (
                                            <ClassicalIcon sx={iconStyle} />
                                          );
                                          break;
                                        case "correspondence":
                                          icon = <DailyIcon sx={iconStyle} />;
                                          break;
                                        default:
                                          icon = <BulletIcon sx={iconStyle} />;
                                      }
                                      return (
                                        <>
                                          {icon}
                                          {userItem.gameType}
                                        </>
                                      );
                                    })()}
                                  </Box>
                                </TableCell>

                                {renderRow(userItem, opponentItem)}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
              </div>
            </Box>
          )
        }

        {
          /* Ratings */ activeTab === 1 && (
            <>
              <Box height={500}>
                {userRatingHistory && opponentRatingHistory && (
                  <>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection={isSmallScreen ? "column" : "row"}
                      sx={{ height: "400px", width: "100%" }}
                    >
                      <Box
                        flex="1"
                        marginRight={isSmallScreen ? "0" : "10px"}
                        sx={{ minWidth: 0, height: "400px" }}
                      >
                        <ResponsiveLine
                          data={combinedRatingHistory}
                          xScale={{
                            type: "time",
                            format: "%Y-%m-%d",
                            precision: "day",
                          }}
                          xFormat="time:%Y-%m-%d"
                          margin={{ top: 20, right: 20, bottom: 80, left: 60 }}
                          axisTop={null}
                          axisRight={null}
                          curve="monotoneX"
                          colors={{ scheme: "tableau10" }} // You can customize the color scheme here
                          axisBottom={{
                            format: "%Y",
                            tickValues: "every year",
                            orient: "bottom",
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,
                          }}
                          axisLeft={{
                            orient: "left",
                            tickSize: 0,
                            tickPadding: 10,
                            tickRotation: 0,
                            legendPosition: "middle",
                          }}
                          yScale={{
                            type: "linear",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                          }}
                          pointSize={3}
                          pointColor={{ theme: "background" }}
                          pointBorderWidth={1}
                          pointBorderColor={{ from: "serieColor" }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                          tooltip={({ point }) => {
                            const pointIdBeforeDot = point.id.split(".")[0];
                            return (
                              <div
                                style={{
                                  background: colors.black[100],
                                  padding: "9px 12px",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <strong>{pointIdBeforeDot}</strong>
                                <br />
                                Date: <strong>{point.data.xFormatted}</strong>
                                <br />
                                Rating: <strong>{point.data.yFormatted}</strong>
                                <br />
                              </div>
                            );
                          }}
                          legends={[
                            {
                              anchor: "bottom-left", // Position the legend at the bottom left
                              direction: "row", // Arrange items in a row
                              justify: false,
                              translateX: 0, // Fine-tune the horizontal position
                              translateY: 60, // Fine-tune the vertical position
                              itemsSpacing: 100, // Adjust spacing between items
                              itemDirection: "left-to-right",
                              itemWidth: 80,
                              itemHeight: 20,
                              itemOpacity: 0.75,
                              symbolSize: 12,
                              symbolShape: "circle",
                              symbolBorderColor: "rgba(0, 0, 0, .5)",
                              effects: [
                                {
                                  on: "hover",
                                  style: {
                                    itemBackground: "rgba(0, 0, 0, .03)",
                                    itemOpacity: 1,
                                  },
                                },
                              ],
                            },
                          ]}
                          theme={{
                            grid: {
                              line: {
                                stroke: colors.black[400],
                                strokeWidth: 0.25,
                              },
                            },
                            tooltip: {
                              container: {
                                background: colors.grey[100],
                                color: "white",
                                fontSize: "12px",
                                borderRadius: "2px",
                                boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
                                padding: "5px 9px",
                              },
                            },
                          }}
                        />
                      </Box>
                      <Box
                        flex="1"
                        display="flex"
                        flexDirection="column"
                        sx={{ maxWidth: isSmallScreen ? "100%" : "200px" }}
                      >
                        {/* Controls for Game Type, Date Part, Start Date, and End Date */}
                        <Box>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel>Game Type</InputLabel>
                            <Select
                              value={selectedGameType}
                              onChange={handleGameTypeChange}
                              label="Game Type"
                            >
                              {gameTypes.map((gameType) => (
                                <MenuItem key={gameType} value={gameType}>
                                  {gameType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel>Date Part</InputLabel>
                            <Select
                              value={timeAggregation}
                              onChange={handleTimeAggregationChange}
                              label="Date Part"
                            >
                              <MenuItem value="Day">Day</MenuItem>
                              <MenuItem value="Week">Week</MenuItem>
                              <MenuItem value="Month">Month</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <TextField
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                          />
                        </Box>
                        <Box>
                          <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )
        }
        {
          /* Opening Table */ activeTab === 2 && (
            <Box sx={{ marginTop: 2 }}>
              <ToggleButtonGroup
                value={colorView}
                exclusive
                onChange={handleColorViewChange}
                aria-label="color view"
                sx={{ marginBottom: 2 }}
              >
                <ToggleButton value="white" aria-label="white games">
                  Playing as White
                </ToggleButton>
                <ToggleButton value="black" aria-label="black games">
                  Playing as Black
                </ToggleButton>
              </ToggleButtonGroup>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterGames}
                    onChange={(event) => setFilterGames(event.target.checked)}
                    sx={{
                      color:
                        theme.palette.mode === "dark"
                          ? colors.grey[100]
                          : colors.black[900],
                      "&.Mui-checked": {
                        color:
                          theme.palette.mode === "dark"
                            ? colors.green[500]
                            : colors.green[500],
                      },
                    }}
                  />
                }
                sx={{
                  pl: 2,
                  color:
                    theme.palette.mode === "dark"
                      ? colors.grey[100]
                      : colors.black[900],
                }}
                label="Filter Unmatched Openings"
              />

              <TableContainer component={Paper} sx={{ width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sortDirection={orderBy === "opening" ? order : false}
                        sx={{ width: "400px" }}
                      >
                        <TableSortLabel
                          active={orderBy === "opening"}
                          direction={orderBy === "opening" ? order : "asc"}
                          onClick={() => handleRequestSort("opening")}
                        >
                          Opening
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={
                          orderBy === "win_differential" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "win_differential"}
                          direction={
                            orderBy === "win_differential" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("win_differential")}
                        >
                          Win Differential
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={orderBy === "user_games" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "user_games"}
                          direction={orderBy === "user_games" ? order : "asc"}
                          onClick={() => handleRequestSort("user_games")}
                        >
                          {username} Games
                        </TableSortLabel>
                      </TableCell>

                      {/* Combine Wins, Draws, and Losses into one column */}
                      <TableCell>
                        {username}
                        <br />W / D / L
                      </TableCell>

                      <TableCell
                        sortDirection={
                          orderBy === "opponent_games" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "opponent_games"}
                          direction={
                            orderBy === "opponent_games" ? order : "asc"
                          }
                          onClick={() => handleRequestSort("opponent_games")}
                        >
                          {opponent} Games
                        </TableSortLabel>
                      </TableCell>

                      {/* Combine Opponent Wins, Draws, and Losses into one column */}
                      <TableCell>
                        {opponent}
                        <br />W / D / L
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTableData.map((row) => {
                      return (
                        <TableRow key={row.opening}>
                          <TableCell sx={{ width: "400px" }}>
                            <strong>{row.opening}</strong>
                            <br />
                            <small>
                              {row.eco} <small>|</small> {row.line}
                            </small>
                          </TableCell>
                          <TableCell>
                            <strong
                              style={{
                                color:
                                  (isNaN(row.user_wins / row.user_games)
                                    ? 0.0
                                    : (row.user_wins / row.user_games) * 100) -
                                    (isNaN(
                                      row.opponent_wins / row.opponent_games
                                    )
                                      ? 0.0
                                      : (row.opponent_wins /
                                          row.opponent_games) *
                                        100) >
                                  0
                                    ? "green"
                                    : (isNaN(row.user_wins / row.user_games)
                                        ? 0.0
                                        : (row.user_wins / row.user_games) *
                                          100) -
                                        (isNaN(
                                          row.opponent_wins / row.opponent_games
                                        )
                                          ? 0.0
                                          : (row.opponent_wins /
                                              row.opponent_games) *
                                            100) <
                                      0
                                    ? "red"
                                    : colors.black[900],
                              }}
                            >
                              {(
                                (isNaN(row.user_wins / row.user_games)
                                  ? 0.0
                                  : (row.user_wins / row.user_games) * 100) -
                                (isNaN(row.opponent_wins / row.opponent_games)
                                  ? 0.0
                                  : (row.opponent_wins / row.opponent_games) *
                                    100)
                              ).toFixed(1)}
                            </strong>
                            <br />
                            <small>&nbsp;</small>
                            <br />
                            <small>&nbsp;</small>
                          </TableCell>

                          <TableCell>
                            <strong>{row.user_games}</strong>
                            <br />
                            <small>
                              {isNaN(row.user_games / totalUserGames)
                                ? "0.0"
                                : (
                                    (row.user_games / totalUserGames) *
                                    100
                                  ).toFixed(1)}
                              %
                            </small>
                            <br />
                            <small>&nbsp;</small>
                          </TableCell>

                          {/* Combined User W / D / L */}
                          <TableCell>
                            <strong>{row.user_wins}</strong> /{" "}
                            <strong>{row.user_draws}</strong> /{" "}
                            <strong>{row.user_losses}</strong>
                            <br />
                            <small>
                              {`${
                                isNaN(row.user_wins / row.user_games)
                                  ? "0.0"
                                  : (
                                      (row.user_wins / row.user_games) *
                                      100
                                    ).toFixed(0)
                              }% / ${
                                isNaN(row.user_draws / row.user_games)
                                  ? "0.0"
                                  : (
                                      (row.user_draws / row.user_games) *
                                      100
                                    ).toFixed(0)
                              }% / ${
                                isNaN(row.user_losses / row.user_games)
                                  ? "0.0"
                                  : (
                                      (row.user_losses / row.user_games) *
                                      100
                                    ).toFixed(0)
                              }%`}
                            </small>
                            <div style={{ height: "20px", width: "150px" }}>
                              <ResponsiveBar
                                data={[
                                  {
                                    type: "results",
                                    wins: isNaN(row.user_wins / row.user_games)
                                      ? 0
                                      : (row.user_wins / row.user_games) * 100,
                                    draws: isNaN(
                                      row.user_draws / row.user_games
                                    )
                                      ? 0
                                      : (row.user_draws / row.user_games) * 100,
                                    losses: isNaN(
                                      row.user_losses / row.user_games
                                    )
                                      ? 0
                                      : (row.user_losses / row.user_games) *
                                        100,
                                  },
                                ]}
                                keys={["wins", "draws", "losses"]}
                                indexBy="type"
                                margin={{
                                  top: 0,
                                  right: 10,
                                  bottom: 0,
                                  left: 0,
                                }}
                                layout="horizontal"
                                colors={({ id, data }) => {
                                  if (id === "wins") return colors.green[500];
                                  if (id === "losses") return colors.red[300];
                                  if (id === "draws") return colors.grey[500];
                                  return "#000";
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={null}
                                axisLeft={null}
                                enableLabel={false}
                                isInteractive={false}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </div>
                          </TableCell>

                          <TableCell>
                            <strong>{row.opponent_games}</strong>
                            <br />
                            <small>
                              {isNaN(row.opponent_games / totalOpponentGames)
                                ? "0.0"
                                : (
                                    (row.opponent_games / totalOpponentGames) *
                                    100
                                  ).toFixed(1)}
                              %
                            </small>
                            <br />
                            <small>&nbsp;</small>
                          </TableCell>

                          {/* Combined Opponent W / D / L */}
                          <TableCell>
                            <strong>{row.opponent_wins}</strong> /{" "}
                            <strong>{row.opponent_draws}</strong> /{" "}
                            <strong>{row.opponent_losses}</strong>
                            <br />
                            <small>
                              {`${
                                isNaN(row.opponent_wins / row.opponent_games)
                                  ? "0.0"
                                  : (
                                      (row.opponent_wins / row.opponent_games) *
                                      100
                                    ).toFixed(0)
                              }% / ${
                                isNaN(row.opponent_draws / row.opponent_games)
                                  ? "0.0"
                                  : (
                                      (row.opponent_draws /
                                        row.opponent_games) *
                                      100
                                    ).toFixed(0)
                              }% / ${
                                isNaN(row.opponent_losses / row.opponent_games)
                                  ? "0.0"
                                  : (
                                      (row.opponent_losses /
                                        row.opponent_games) *
                                      100
                                    ).toFixed(0)
                              }%`}
                            </small>
                            <div style={{ height: "20px", width: "150px" }}>
                              <ResponsiveBar
                                data={[
                                  {
                                    type: "results",
                                    wins: isNaN(
                                      row.opponent_wins / row.opponent_games
                                    )
                                      ? 0
                                      : (row.opponent_wins /
                                          row.opponent_games) *
                                        100,
                                    draws: isNaN(
                                      row.opponent_draws / row.opponent_games
                                    )
                                      ? 0
                                      : (row.opponent_draws /
                                          row.opponent_games) *
                                        100,
                                    losses: isNaN(
                                      row.opponent_losses / row.opponent_games
                                    )
                                      ? 0
                                      : (row.opponent_losses /
                                          row.opponent_games) *
                                        100,
                                  },
                                ]}
                                keys={["wins", "draws", "losses"]}
                                indexBy="type"
                                margin={{
                                  top: 0,
                                  right: 10,
                                  bottom: 0,
                                  left: 0,
                                }}
                                layout="horizontal"
                                colors={({ id, data }) => {
                                  if (id === "wins") return colors.green[500];
                                  if (id === "losses") return colors.red[300];
                                  if (id === "draws") return colors.grey[500];
                                  return "#000";
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={null}
                                axisLeft={null}
                                enableLabel={false}
                                isInteractive={false}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        }
        {
          /* Opening Explorer */
          activeTab === 3 && (
            <Box sx={{ marginTop: 2 }}>
              {/* Move ToggleButtonGroup to the top */}
              <ToggleButtonGroup
                value={colorView}
                exclusive
                onChange={handleColorViewChange}
                aria-label="color view"
                sx={{ marginBottom: 2 }}
              >
                <ToggleButton value="white" aria-label="white games">
                  Playing as White
                </ToggleButton>
                <ToggleButton value="black" aria-label="black games">
                  Playing as Black
                </ToggleButton>
              </ToggleButtonGroup>

              <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
                {/* User Table Playing as White*/}
                {colorView === "white" && (
                  <Box sx={{ flex: 2 }}>
                    <Typography variant="h7">
                      <b>{username}</b> games as{" "}
                      <b>{colorView === "white" ? "White" : "Black"}</b>
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", marginBottom: 4, mt: 1 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "120px" }}>Move</TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell sx={{ width: "120px" }}>
                              Games Played
                            </TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell>W / D / L</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(aggregatedGameData)
                            .sort(
                              (a, b) =>
                                b.username.gamesPlayed - a.username.gamesPlayed
                            ) // Sort by games played, descending
                            .map((row, index) => {
                              if (row.username.gamesPlayed > 0) {
                                const totalGamesPlayed = Object.values(
                                  aggregatedGameData
                                ).reduce(
                                  (acc, game) =>
                                    acc + game.username.gamesPlayed,
                                  0
                                );

                                const gamePercentage =
                                  (row.username.gamesPlayed /
                                    totalGamesPlayed) *
                                  100;
                                const winPercentage =
                                  (row.username.wins /
                                    row.username.gamesPlayed) *
                                  100;
                                const drawPercentage =
                                  (row.username.draws /
                                    row.username.gamesPlayed) *
                                  100;
                                const lossPercentage =
                                  (row.username.losses /
                                    row.username.gamesPlayed) *
                                  100;

                                const barData = [
                                  {
                                    type: "results",
                                    Wins: winPercentage,
                                    Draws: drawPercentage,
                                    Losses: lossPercentage,
                                  },
                                ];

                                // Determine the move number and prefix based on moveIndex
                                const moveNumber =
                                  Math.floor(moveIndex / 2) + 1;
                                const movePrefix =
                                  moveIndex % 2 === 0
                                    ? `${moveNumber}.`
                                    : `${moveNumber}..`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{ width: "120px" }}
                                    >{`${movePrefix} ${row.move}`}</TableCell>{" "}
                                    {/* Fixed width */}
                                    <TableCell sx={{ width: "120px" }}>
                                      <strong>
                                        {row.username.gamesPlayed}
                                      </strong>
                                      <br />
                                      <small>
                                        {gamePercentage.toFixed(1)}%
                                      </small>
                                    </TableCell>
                                    <TableCell>
                                      {/* Consolidated Wins, Draws, Losses with W / D / L */}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div>
                                          <strong>
                                            {row.username.wins} /{" "}
                                            {row.username.draws} /{" "}
                                            {row.username.losses}
                                          </strong>
                                        </div>
                                        <div>
                                          <small>
                                            {winPercentage.toFixed(1)}% /{" "}
                                            {drawPercentage.toFixed(1)}% /{" "}
                                            {lossPercentage.toFixed(1)}%
                                          </small>
                                        </div>
                                        <div
                                          style={{
                                            height: "20px",
                                            width: "150px",
                                          }}
                                        >
                                          <ResponsiveBar
                                            data={barData}
                                            keys={["Wins", "Draws", "Losses"]}
                                            indexBy="type"
                                            layout="horizontal"
                                            margin={{
                                              top: 0,
                                              right: 10,
                                              bottom: 0,
                                              left: 0,
                                            }}
                                            colors={({ id }) => {
                                              if (id === "Wins")
                                                return colors.green[500];
                                              if (id === "Losses")
                                                return colors.red[300];
                                              if (id === "Draws")
                                                return colors.grey[500];
                                              return "#000";
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={null}
                                            axisLeft={null}
                                            enableLabel={false}
                                            isInteractive={false}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            groupMode="stacked"
                                          />
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {/* Opponent Table Playing as White*/}
                {colorView === "black" && (
                  <Box sx={{ flex: 2 }}>
                    <Typography variant="h7" sx={{ pb: 3 }}>
                      <b>{opponent}</b> games as{" "}
                      <b>{colorView === "white" ? "Black" : "White"}</b>
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", marginBottom: 4, mt: 1 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "120px" }}>Move</TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell sx={{ width: "120px" }}>
                              Games Played
                            </TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell>W / D / L</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(aggregatedGameData)
                            .sort(
                              (a, b) =>
                                b.opponent.gamesPlayed - a.opponent.gamesPlayed
                            ) // Sort by games played, descending
                            .map((row, index) => {
                              if (row.opponent.gamesPlayed > 0) {
                                const totalGamesPlayed = Object.values(
                                  aggregatedGameData
                                ).reduce(
                                  (acc, game) =>
                                    acc + game.opponent.gamesPlayed,
                                  0
                                );

                                const gamePercentage =
                                  (row.opponent.gamesPlayed /
                                    totalGamesPlayed) *
                                  100;
                                const winPercentage =
                                  (row.opponent.wins /
                                    row.opponent.gamesPlayed) *
                                  100;
                                const drawPercentage =
                                  (row.opponent.draws /
                                    row.opponent.gamesPlayed) *
                                  100;
                                const lossPercentage =
                                  (row.opponent.losses /
                                    row.opponent.gamesPlayed) *
                                  100;

                                const barData = [
                                  {
                                    type: "results",
                                    Wins: winPercentage,
                                    Draws: drawPercentage,
                                    Losses: lossPercentage,
                                  },
                                ];

                                // Determine the move number and prefix based on moveIndex
                                const moveNumber =
                                  Math.floor(moveIndex / 2) + 1;
                                const movePrefix =
                                  moveIndex % 2 === 0
                                    ? `${moveNumber}.`
                                    : `${moveNumber}..`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{ width: "120px" }}
                                    >{`${movePrefix} ${row.move}`}</TableCell>{" "}
                                    {/* Fixed width */}
                                    <TableCell sx={{ width: "120px" }}>
                                      <strong>
                                        {row.opponent.gamesPlayed}
                                      </strong>
                                      <br />
                                      <small>
                                        {gamePercentage.toFixed(1)}%
                                      </small>
                                    </TableCell>
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div>
                                          <strong>
                                            {row.opponent.wins} /{" "}
                                            {row.opponent.draws} /{" "}
                                            {row.opponent.losses}
                                          </strong>
                                        </div>
                                        <div>
                                          <small>
                                            {winPercentage.toFixed(1)}% /{" "}
                                            {drawPercentage.toFixed(1)}% /{" "}
                                            {lossPercentage.toFixed(1)}%
                                          </small>
                                        </div>
                                        <div
                                          style={{
                                            height: "20px",
                                            width: "150px",
                                          }}
                                        >
                                          <ResponsiveBar
                                            data={barData}
                                            keys={["Wins", "Draws", "Losses"]}
                                            indexBy="type"
                                            layout="horizontal"
                                            margin={{
                                              top: 0,
                                              right: 10,
                                              bottom: 0,
                                              left: 0,
                                            }}
                                            colors={({ id }) => {
                                              if (id === "Wins")
                                                return colors.green[500];
                                              if (id === "Losses")
                                                return colors.red[300];
                                              if (id === "Draws")
                                                return colors.grey[500];
                                              return "#000";
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={null}
                                            axisLeft={null}
                                            enableLabel={false}
                                            isInteractive={false}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            groupMode="stacked"
                                          />
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {/* Chessboard */}
                <Box sx={{ width: "400px", flexShrink: 0, pt: 4 }}>
                  <Chessboard
                    position={boardPosition}
                    onPieceDrop={onDrop}
                    boardOrientation={colorView}
                    customLightSquareStyle={{
                      backgroundColor:
                        themeColors[userData?.theme || "Modern Minimal"]
                          .lightSquare,
                      backgroundImage:
                        themeColors[userData?.theme || "Modern Minimal"]
                          .lightSquarePattern,
                    }}
                    customDarkSquareStyle={{
                      backgroundColor:
                        themeColors[userData?.theme || "Modern Minimal"]
                          .darkSquare,
                      backgroundImage:
                        themeColors[userData?.theme || "Modern Minimal"]
                          .darkSquarePattern,
                    }}
                    customPieces={pieceSets[userData?.pieceset || "maestro"]}
                  />

                  <Typography sx={{ pt: 1 }}>
                    {formatPlayedMoves(playedMoves)}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, mt: 2, mb: 2 }}>
                    <Button variant="contained" onClick={handleBackMove}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleResetPlayedMoves}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>

                {/* User Table Playing as Black */}
                {colorView === "black" && (
                  <Box sx={{ flex: 2 }}>
                    <Typography variant="h7" sx={{ marginBottom: 2 }}>
                      <b>{username}</b> games as{" "}
                      <b>{colorView === "white" ? "White" : "Black"}</b>
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", marginBottom: 4, mt: 1 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "120px" }}>Move</TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell sx={{ width: "120px" }}>
                              Games Played
                            </TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell>W / D / L</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(aggregatedGameData)
                            .sort(
                              (a, b) =>
                                b.username.gamesPlayed - a.username.gamesPlayed
                            ) // Sort by games played, descending
                            .map((row, index) => {
                              if (row.username.gamesPlayed > 0) {
                                const totalGamesPlayed = Object.values(
                                  aggregatedGameData
                                ).reduce(
                                  (acc, game) =>
                                    acc + game.username.gamesPlayed,
                                  0
                                );

                                const gamePercentage =
                                  (row.username.gamesPlayed /
                                    totalGamesPlayed) *
                                  100;
                                const winPercentage =
                                  (row.username.wins /
                                    row.username.gamesPlayed) *
                                  100;
                                const drawPercentage =
                                  (row.username.draws /
                                    row.username.gamesPlayed) *
                                  100;
                                const lossPercentage =
                                  (row.username.losses /
                                    row.username.gamesPlayed) *
                                  100;

                                const barData = [
                                  {
                                    type: "results",
                                    Wins: winPercentage,
                                    Draws: drawPercentage,
                                    Losses: lossPercentage,
                                  },
                                ];

                                // Determine the move number and prefix based on moveIndex
                                const moveNumber =
                                  Math.floor(moveIndex / 2) + 1;
                                const movePrefix =
                                  moveIndex % 2 === 0
                                    ? `${moveNumber}.`
                                    : `${moveNumber}..`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{ width: "120px" }}
                                    >{`${movePrefix} ${row.move}`}</TableCell>{" "}
                                    {/* Fixed width */}
                                    <TableCell sx={{ width: "120px" }}>
                                      <strong>
                                        {row.username.gamesPlayed}
                                      </strong>
                                      <br />
                                      <small>
                                        {gamePercentage.toFixed(1)}%
                                      </small>
                                    </TableCell>
                                    <TableCell>
                                      {/* Consolidated Wins, Draws, Losses with W / D / L */}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div>
                                          <strong>
                                            {row.username.wins} /{" "}
                                            {row.username.draws} /{" "}
                                            {row.username.losses}
                                          </strong>
                                        </div>
                                        <div>
                                          <small>
                                            {winPercentage.toFixed(1)}% /{" "}
                                            {drawPercentage.toFixed(1)}% /{" "}
                                            {lossPercentage.toFixed(1)}%
                                          </small>
                                        </div>
                                        <div
                                          style={{
                                            height: "20px",
                                            width: "150px",
                                          }}
                                        >
                                          <ResponsiveBar
                                            data={barData}
                                            keys={["Wins", "Draws", "Losses"]}
                                            indexBy="type"
                                            layout="horizontal"
                                            margin={{
                                              top: 0,
                                              right: 10,
                                              bottom: 0,
                                              left: 0,
                                            }}
                                            colors={({ id }) => {
                                              if (id === "Wins")
                                                return colors.green[500];
                                              if (id === "Losses")
                                                return colors.red[300];
                                              if (id === "Draws")
                                                return colors.grey[500];
                                              return "#000";
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={null}
                                            axisLeft={null}
                                            enableLabel={false}
                                            isInteractive={false}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            groupMode="stacked"
                                          />
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {/* Opponent Table Playing as Black*/}
                {colorView === "white" && (
                  <Box sx={{ flex: 2 }}>
                    <Typography variant="h7" sx={{ marginBottom: 2 }}>
                      <b>{opponent}</b> games as{" "}
                      <b>{colorView === "white" ? "Black" : "White"}</b>
                    </Typography>
                    <TableContainer
                      component={Paper}
                      sx={{ width: "100%", marginBottom: 4, mt: 1 }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: "120px" }}>Move</TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell sx={{ width: "120px" }}>
                              Games Played
                            </TableCell>{" "}
                            {/* Fixed width */}
                            <TableCell>W / D / L</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(aggregatedGameData)
                            .sort(
                              (a, b) =>
                                b.opponent.gamesPlayed - a.opponent.gamesPlayed
                            ) // Sort by games played, descending
                            .map((row, index) => {
                              if (row.opponent.gamesPlayed > 0) {
                                const totalGamesPlayed = Object.values(
                                  aggregatedGameData
                                ).reduce(
                                  (acc, game) =>
                                    acc + game.opponent.gamesPlayed,
                                  0
                                );

                                const gamePercentage =
                                  (row.opponent.gamesPlayed /
                                    totalGamesPlayed) *
                                  100;
                                const winPercentage =
                                  (row.opponent.wins /
                                    row.opponent.gamesPlayed) *
                                  100;
                                const drawPercentage =
                                  (row.opponent.draws /
                                    row.opponent.gamesPlayed) *
                                  100;
                                const lossPercentage =
                                  (row.opponent.losses /
                                    row.opponent.gamesPlayed) *
                                  100;

                                const barData = [
                                  {
                                    type: "results",
                                    Wins: winPercentage,
                                    Draws: drawPercentage,
                                    Losses: lossPercentage,
                                  },
                                ];

                                // Determine the move number and prefix based on moveIndex
                                const moveNumber =
                                  Math.floor(moveIndex / 2) + 1;
                                const movePrefix =
                                  moveIndex % 2 === 0
                                    ? `${moveNumber}.`
                                    : `${moveNumber}..`;

                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      sx={{ width: "120px" }}
                                    >{`${movePrefix} ${row.move}`}</TableCell>{" "}
                                    {/* Fixed width */}
                                    <TableCell sx={{ width: "120px" }}>
                                      <strong>
                                        {row.opponent.gamesPlayed}
                                      </strong>
                                      <br />
                                      <small>
                                        {gamePercentage.toFixed(1)}%
                                      </small>
                                    </TableCell>
                                    <TableCell>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div>
                                          <strong>
                                            {row.opponent.wins} /{" "}
                                            {row.opponent.draws} /{" "}
                                            {row.opponent.losses}
                                          </strong>
                                        </div>
                                        <div>
                                          <small>
                                            {winPercentage.toFixed(1)}% /{" "}
                                            {drawPercentage.toFixed(1)}% /{" "}
                                            {lossPercentage.toFixed(1)}%
                                          </small>
                                        </div>
                                        <div
                                          style={{
                                            height: "20px",
                                            width: "150px",
                                          }}
                                        >
                                          <ResponsiveBar
                                            data={barData}
                                            keys={["Wins", "Draws", "Losses"]}
                                            indexBy="type"
                                            layout="horizontal"
                                            margin={{
                                              top: 0,
                                              right: 10,
                                              bottom: 0,
                                              left: 0,
                                            }}
                                            colors={({ id }) => {
                                              if (id === "Wins")
                                                return colors.green[500];
                                              if (id === "Losses")
                                                return colors.red[300];
                                              if (id === "Draws")
                                                return colors.grey[500];
                                              return "#000";
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={null}
                                            axisLeft={null}
                                            enableLabel={false}
                                            isInteractive={false}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            groupMode="stacked"
                                          />
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                              return null;
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </Box>
          )
        }
      </Box>
    </Box>
  );
}

export default OpponentPrep;
