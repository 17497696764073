import { ColorModeContext, useMode } from "./styles/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Other/Homepage";
import Layout from "./components/Layout";
import NotFound from "./pages/Other/NotFound";
import { useEffect } from "react";

import GuessTheOpening from "./pages/Games/GuessTheOpening";
import GuessTheEval from "./pages/Games/GuessTheEval";
import PlayTheOpening from "./pages/Games/PlayTheOpening";
import Profile from "./pages/Other/Profile";
import Wordsearch from "./pages/Games/Wordsearch";
import GuessTheElo from "./pages/Games/GuessTheElo";
import ChessSlide from "./pages/Games/ChessSlide";
import FischerRandomGenerator from "./pages/Tools/FischerRandomGenerator";
import OpeningExplorer from "./pages/Tools/OpeningExplorer";
import Hangman from "./pages/Games/Hangman";
import About from "./pages/Other/About";
import { usePageTracking } from "./config/ga";
import Glossary from "./pages/Library/Glossary";
import Quotes from "./pages/Library/Quotes";
import Anagrams from "./pages/Games/Anagrams";
import ImagePuzzle from "./pages/Games/ImagePuzzle";
import NotationTrainer from "./pages/Tools/NotationTrainer";

import { UserProvider, useUser } from "./context/UserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./config/firebase";
import { updateUserFromAuth } from "./features/Firestore";
import Login from "./pages/Other/Login";
import OpponentPrep from "./pages/Analytics/OpponentPrep";
//import MyProfile from "./pages/Analytics/MyProfile";
import UserScout from "./pages/Analytics/UserScout";
import Privacy from "./pages/Other/Privacy";
import WorldChampionships from "./pages/Library/WorldChampionships";
import ClassicGames from "./pages/Library/ClassicGames";
import BasicRulesOfPlay from "./pages/Library/BasicRulesOfPlay";
import ChessPrinciples from "./pages/Learn/ChessPrinciples";
import ChessOpenings from "./pages/Learn/ChessOpenings";
import CompetitiveRulesOfPlay from "./pages/Library/CompetitiveRulesOfPlay";
import Crossword from "./pages/Games/Crossword";
import GifGenerator from "./pages/Tools/GifGenerator";
import ChessResources from "./pages/Learn/ChessResources";
import MiniatureGames from "./pages/Library/Miniature";
import { ShareProvider } from "./context/ShareContext";
//import ECFRatingsExplorer from "./pages/Tools/ECFRatingsExplorer";

function AuthenticatedApp() {
  usePageTracking();
  const [user] = useAuthState(auth);
  const { setUserData, setUserRef } = useUser();

  useEffect(() => {
    updateUserFromAuth(user, setUserData, setUserRef);
  }, [user, setUserData, setUserRef]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/** Other */}
        <Route path="/" element={<Homepage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        {/** Puzzles */}
        <Route path="/chessslide" element={<ChessSlide />} />
        <Route path="/anagrams" element={<Anagrams />} />
        <Route path="/guesstheelo/:gameId" element={<GuessTheElo />} />
        <Route path="/guesstheelo" element={<GuessTheElo />} />
        <Route path="/guesstheeval/:gameId" element={<GuessTheEval />} />
        <Route path="/guesstheeval" element={<GuessTheEval />} />
        <Route
          path="/guesstheopening/:lengthId/:gameId"
          element={<GuessTheOpening />}
        />
        <Route path="/guesstheopening" element={<GuessTheOpening />} />
        <Route
          path="/playtheopening/:lengthId/:gameId"
          element={<PlayTheOpening />}
        />
        <Route path="/playtheopening" element={<PlayTheOpening />} />
        <Route path="/wordsearch" element={<Wordsearch />} />
        <Route path="/hangman" element={<Hangman />} />
        <Route path="/imagepuzzle" element={<ImagePuzzle />} />
        <Route path="/crossword" element={<Crossword />} />
        {/** Learn 

        <Route path="/chessprinciples" element={<ChessPrinciples />} />
        <Route path="/chessopenings" element={<ChessOpenings />} />*/}
        {/** Library */}
        <Route path="/glossary" element={<Glossary />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/worldchampionships" element={<WorldChampionships />} />
        <Route path="/classicgames" element={<ClassicGames />} />

        <Route path="/miniaturegames" element={<MiniatureGames />} />
        <Route path="/basicrulesofplay" element={<BasicRulesOfPlay />} />
        <Route
          path="/competitiverulesofplay"
          element={<CompetitiveRulesOfPlay />}
        />
        {/** Analytics */}
        <Route path="/userscout" element={<UserScout />} />
        <Route path="/opponentprep" element={<OpponentPrep />} />

        {/** Tools */}
        <Route path="/fischerrandom" element={<FischerRandomGenerator />} />
        <Route path="/openingexplorer" element={<OpeningExplorer />} />
        <Route path="/notationtrainer" element={<NotationTrainer />} />
        <Route path="/gifgenerator" element={<GifGenerator />} />
        {/*<Route path="/ecfratingsexplorer" element={<ECFRatingsExplorer />} />*/}

        {/** Learn */}
        <Route path="/chessprinciples" element={<ChessPrinciples />} />
        <Route path="/chessopenings" element={<ChessOpenings />} />
        <Route path="/chessresources" element={<ChessResources />} />
        {/** Login*/}
        <Route path="/login" element={<Login />} />
        {/** Other*/}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    document.cookie = "name=value; SameSite=None; Secure";
  }, []);

  return (
    <UserProvider>
      <ShareProvider>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthenticatedApp />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </ShareProvider>
    </UserProvider>
  );
}

export default App;
